import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    authEndpoint: '/broadcasting/auth', // Endpoint para autenticar el canal privado
    auth: {
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="_token"]').getAttribute('content'),
        }
    }
});

document.addEventListener('DOMContentLoaded', function () {
    const isLoggedIn = document.body.getAttribute('data-logged-in');
    window.Echo.private(`notifications.${isLoggedIn}`)
        .listen('.notify', (data) => {
            const toast = new StackedToastManager();
            toast.mostrarToast(data.message, data.title, data.status);
        });

    const toast = new StackedToastManager();
    toast.cargarToasts();
});
