export default class ToastManager {
    constructor(containerId = "toastContainer") {
        this.toastCounter = 0;
        this.toastContainer = document.getElementById(containerId);

        // Si el contenedor no existe, lo creamos dinámicamente
        if (!this.toastContainer) {
            this.toastContainer = document.createElement("div");
            this.toastContainer.id = containerId;
            this.toastContainer.className = "toast-container position-fixed bottom-0 end-0 p-3";
            document.body.appendChild(this.toastContainer);
        }

        // Cargar toasts previos desde localStorage
        this.cargarToasts();
    }

    mostrarToast(mensaje = "Mensaje de prueba", titulo = "Notificación", tipo = "info") {
        this.toastCounter++;

        // Diccionario de íconos y colores según el tipo de mensaje
        const ahora = new Date();
        const horaActual = ahora.toLocaleDateString("es-ES", {
            day: '2-digit',
            month: 'short'
        }) + ' ' + ahora.toLocaleTimeString("es-ES", {
            hour: '2-digit',
            minute: '2-digit'
        });

        const toastId = `toast-${Date.now()}-${this.toastCounter}`; // ID único para evitar colisiones
        const toastData = {id: toastId, titulo, mensaje, tipo, horaActual};

        // Guardar en localStorage
        this.guardarToast(toastData);

        // Renderizar toast en pantalla
        this.crearToastElement(toastData);
    }

    crearToastElement({id, titulo, mensaje, tipo, horaActual}) {
        // Verificar si el toast ya existe en el DOM para evitar duplicados
        if (document.getElementById(id)) return;

        const tipoConfig = {
            "success": {icon: "mdi-checkbox-marked-circle", color: "text-success"},
            "error": {icon: "mdi-alert-circle", color: "text-danger"},
            "warning": {icon: "mdi-alert-circle", color: "text-warning"},
            "info": {icon: "mdi-information-variant-circle", color: "text-body"}
        };

        const config = tipoConfig[tipo] || tipoConfig["info"];

        // Crear el toast
        const toast = document.createElement("div");
        toast.classList.add("toast", "show");
        toast.id = id;
        toast.setAttribute("role", "alert");
        toast.setAttribute("aria-live", "assertive");
        toast.setAttribute("aria-atomic", "true");

        toast.innerHTML = `
            <div class="toast-body bg-dark text-white">
                <div class="d-flex gap-2">
                    <span><i class="mdi mdi-18px ${config.icon} ${config.color}"></i></span>
                    <div class="d-flex flex-column flex-grow-1 gap-2">
                        <div class="d-flex align-items-center">
                            <span class="fw-semibold">${titulo}</span>
                            <span class="ms-auto">${horaActual}</span>
                            <button type="button" class="btn-close btn-close-white btn-close-sm ms-2" data-id="${id}" aria-label="Cerrar"></button>
                        </div>
                        <span>${mensaje}</span>
                    </div>
                </div>
            </div>
        `;

        // Agregar el toast al contenedor
        this.toastContainer.appendChild(toast);

        // Inicializar el toast con Bootstrap
        const bootstrapToast = new Toast(toast, {autohide: false});
        bootstrapToast.show();

        // Manejar la eliminación manual del toast
        toast.querySelector(".btn-close").addEventListener("click", (event) => {
            const toastId = event.target.getAttribute("data-id");
            this.eliminarToast(toastId);
            toast.remove();
        });
    }

    guardarToast(toastData) {
        let toasts = JSON.parse(localStorage.getItem("toasts")) || [];
        toasts.push(toastData);
        localStorage.setItem("toasts", JSON.stringify(toasts));
    }

    cargarToasts() {
        const toasts = JSON.parse(localStorage.getItem("toasts")) || [];
        toasts.forEach(toast => this.crearToastElement(toast));
    }

    eliminarToast(id) {
        let toasts = JSON.parse(localStorage.getItem("toasts")) || [];
        toasts = toasts.filter(toast => toast.id !== id);
        localStorage.setItem("toasts", JSON.stringify(toasts));
    }
}
